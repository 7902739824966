import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@core/snackBar.notification.service';
import { ArticleService } from '@services/article.service';
import { DriveTimeService } from '@services/drivetime/drivetime.service';
import { UserService } from '@services/user.service';
import { Observable, combineLatest, distinctUntilChanged, filter, map, startWith } from 'rxjs';

import { unemploymentMetadata, wageMetadata } from './landing-metadata';
import { NewsLetterDialogComponent } from './newsletter-subscription/newsletter-dialog.component';
import { ArticlePayload, ThoughtLeadership } from '../research/model/article-info';
import { CommonService } from 'core';
import * as _ from 'lodash';
import { DialogService } from '@core/dialog.service';
import { UserProfileService } from '@services/userProfile.service';
import { Modules } from '../site-management/roles.model';
import { NewsLetterConfig, UserProfileInfo } from 'src/app/models/userProfile.model';
import { CompetitionStaticDataKeyIndexes } from '@services/drivetime/drivetime.model';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  @ViewChild('interestModal') interestModal: TemplateRef<string>;
  MatDialog: MatDialogRef<any>;
  email: string;
  firstName: string;
  userProfile: UserProfileInfo;
  currentDate: string;
  articles: Array<ArticlePayload>;
  thoughtLeadership: Array<ArticlePayload>;
  wageOptions: Highcharts.Options;
  layoffsOptions: Highcharts.Options;
  unemploymentOptions: Highcharts.Options;
  employmentyoyOptions: Highcharts.Options;
  unemploymentkpiOptions: Highcharts.Options;
  inflationkpiOptions: Highcharts.Options;
  unemploymentFilter: UnemploymentType = 'cbsa';
  axisLabelStyle = {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    color: '#000000'
  };
  axisTitleStyle = {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    color: '#000000',
  };
  staticData;
  wageData;
  hasArticleAccess = false;
  unemploymentData = [];
  wageForm: FormGroup;
  unemploymentForm: FormGroup;
  wageFields = wageMetadata;
  unemploymentFields = unemploymentMetadata;
  articleSource = ThoughtLeadership.SOURCE;
  articleIndustry = ThoughtLeadership.INDUSTRY;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private userService: UserService,
    private userProfileService: UserProfileService,
    private driveTimeService: DriveTimeService,
    private driveTimeDialogService: DialogService,
    private dialogService: MatDialog,
    private snackBarService: SnackbarService,
    private articleService: ArticleService,
  ) {}

  ngOnInit(): void {
    this.userProfileService.userAccess$.subscribe((user) => {
      if (user?.moduleAccess) {
        this.hasArticleAccess = user.moduleAccess.includes(Modules.ARTICLES) || user.moduleAccess.includes(Modules.ALL);
      }
    });
    this.wageForm = this.createWageForm();
    this.unemploymentForm = this.createUnemploymentForm();

    this.loadUserProfile();
    this.initDate();
    this.initThoughtLeadership();

    combineLatest([
      this.driveTimeService.getWage(),
      this.driveTimeService.getCompetitionStaticData(),
      this.driveTimeService.getUnemployment(),
      this.userService.getUser()
    ])
    .pipe(filter(([wageData, staticData, unemploymentData, user]) =>  staticData !== null && user !== null))
    .subscribe(([
      wageData,
      staticData,
      unemploymentData,
      user,
    ]) => {
      this.firstName = user?.firstName;
      if (!this.firstName && this.userProfile) {
        this.firstName = this.userProfile.firstName;
      }
      this.getArticlesForLast90Days(this.email);
      const countryId = user.userCountryId;
      this.staticData = staticData;
      this.wageData = wageData;

      const industryOpts = {};
      wageData.reduce((acc, nxt) => {
        if (acc[nxt.industryName]) return acc;
        acc[nxt.industryName] = {
          id: nxt.industryID,
          name: nxt.industryName,
        };
        return acc;
      }, industryOpts);
      // industry
      const flatIndustryOpts = Object.values(industryOpts).flat();
      this.wageFields.industry = {
        ...this.wageFields.industry,
        options: flatIndustryOpts,
      };
      this.wageForm.get('industry').patchValue(flatIndustryOpts);

      // cbsa & state
      const cbsaOpts = staticData[CompetitionStaticDataKeyIndexes.CBSA]?.data.filter((opt) => opt.countryId === countryId) || [];
      const stateOpts = staticData[CompetitionStaticDataKeyIndexes.STATE]?.data?.filter((opt) => opt.countryId === countryId) || [];
      const unemploymentDataSorted = _.orderBy(unemploymentData.filter((filter)=>{return filter.type==="CBSA" && filter.population !== undefined}), ['population'], ['desc']);
      
      this.unemploymentFields.cbsa = {
        ...this.unemploymentFields.cbsa,
        options: cbsaOpts,
      };
      this.unemploymentFields.state = {
        ...this.unemploymentFields.state,
        options: stateOpts,
      };
      
      // Get Top 20 CBSA based on population
      this.unemploymentForm.get('cbsa').patchValue(unemploymentDataSorted.slice(0,20));
      this.unemploymentForm.get('state').patchValue(stateOpts);
      // use name from cbsa staticData
      this.unemploymentData = unemploymentData.map((data) => ({
        ...data,
        name: cbsaOpts.find((cbsa) => cbsa.id === data.id)?.name || data.name,
      }));

      this.wageOptions = this.generateLineGraph(this.generateWageOpts(wageData));
      this.unemploymentOptions = this.generateLineGraph(this.generateUnemployementOpts(this.unemploymentData));

    });
    const currentYear = new Date().getFullYear();
    const yearRange = { startYear: currentYear - 1, endYear: currentYear, startMonth: 1, endMonth: 12 };

    this.driveTimeService.getEmploymentYoY({ startYear: currentYear - 10, endYear: currentYear }).subscribe((res) => { this.employmentyoyOptions = this.kpiBaseOptions(this.generateEmployementYoY(res));});
    this.driveTimeService.getUnemploymentkpi(yearRange).subscribe((res) => { this.unemploymentkpiOptions = this.kpiBaseOptions(this.generateUnemploymentkpiOpts(res));});
    this.driveTimeService.getInflationkpi(yearRange).subscribe((res) => { this.inflationkpiOptions = this.kpiBaseOptions(this.generateinflationkpiOptions(res));});
    this.driveTimeService.getLayoffkpi(yearRange).subscribe((res) => { this.layoffsOptions = this.kpiBaseOptions(this.generatelayoffkpiOptions(res));});

    this.wageForm.valueChanges.subscribe(() => { this.wageOptions = this.generateLineGraph(this.generateWageOpts(this.wageData));});
    this.unemploymentForm
    .valueChanges
    .pipe(
      distinctUntilChanged(),
    )
    .subscribe(() => { this.unemploymentOptions = this.generateLineGraph(this.generateUnemployementOpts(this.unemploymentData));});
  }

  private loadUserProfile() {
    this.userProfileService.getUserProfileData().subscribe((profile) => {      
      this.userProfile = profile;
      this.email = profile?.email;
      if (!this.firstName) {
        this.firstName = profile.firstName;
      }
    })
  }

  get isSubscribed(): boolean {
    if (!this.userProfile) {
      return true;
    }
    const { industries, topics} = this.userProfile?.newsLetter
    return !!industries.length || !!topics.length;
  }

  initDate(): void {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    this.currentDate = `${month} ${date.getDate()}, ${date.getFullYear()}`;
  }


  openArticle(e, id): void {
    e.preventDefault();
    this.router.navigate(['articles'], {
      relativeTo: this.route,
      queryParams: { viewId: id, },
    });
  }

  openExternalArticle(e, url): void {
    window.open(url, '_blank');
  }

  yearofExp(year: number): string {
    switch(year) {
      case 1:
        return 'Entry level';
      case 3:
        return 'Junior level';
      case 5:
        return 'Mid level';
      case 10:
        return 'Senior level';
      default:
        return year.toString();
    }
  }

  generateEmployementYoY(yoykpis) {
    const that = this;
    const type = 'column';
    const title = 'Employment&nbsp;growth&nbsp;(YoY)';
    const footerText =  'Source: U.S. Bureau of Labor Statistics';
    const data = yoykpis.slice(-10).map((x) =>  ({
      name: x.xaxis, y: x.value, z: x.changeRate
    }));
    const info = `${yoykpis.at(-1)?.changeRate?.toFixed(2) || ''}%`;
    const tooltip =  {
      useHTML: true,
      headerFormat: '',
      pointFormatter: function () {
        return `<div>
        <div>United States | <b>${this.name}</b></div>
        <div>Year over year growth = ${typeof this.options?.z  === 'number' ? this.options?.z?.toFixed(2) : this.options?.z}%</div>
        <div># change in employment = ${that.commonService.compactNumbers(this.options?.y, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}</div>
        </div>`;
      },
    };

    return { type, title, footerText, data, info, tooltip };
  }

  generatelayoffkpiOptions(layoffkpis) {
    const that = this;
    const type = 'line';
    const title =  'Layoff&nbsp;change&nbsp;(last&nbsp;3&nbsp;months)';
    const footerText =  'Source: U.S. Bureau of Labor Statistics';
    const categories = layoffkpis.slice(-12).map((x) =>  (x.xaxis));
    const data = layoffkpis.slice(-12).map((x) =>  ({name : x.xaxis, y: x.value, z: x.changeRate, breakdown: x.breakdown}));
    const latestValue = layoffkpis.at(-1)?.changeRate;
    const showFirstandLast = true;
    const info = `${typeof latestValue === 'number' ? latestValue?.toFixed(2) : latestValue}%`;
    const tooltip =  {
      useHTML: true,
      headerFormat: '',
      pointFormatter: function () {
        return `<div>
        <div>United States | <b>${this.name}</b></div>
        <div>Layoff in last 3 months = ${ typeof this.z === 'number'?this.z?.toFixed(2) : this.z}%</div>
        <div>Current total layoffs = ${ that.commonService.compactNumbers(this.y)}</div>
        <div>Current layoffs for Nonfarm: ${that.commonService.compactNumbers(this.options.breakdown.TotalNonfarm)}</div>
        <div>Current layoffs for Private: ${that.commonService.compactNumbers(this.options.breakdown.TotalPrivate)}</div>
        <div>Current layoffs for Government: ${that.commonService.compactNumbers(this.options.breakdown.Government)}</div>
        </div>`;
      },
    };
    return { type, title, footerText, data, info, tooltip, categories, showFirstandLast };
  }

  generateinflationkpiOptions(inflationkpis) {
    const that = this;
    const type = 'line';
    const title = 'Inflation rate';
    const footerText =  'Source: U.S. Bureau of Labor Statistics';
    const categories = inflationkpis.slice(-12).map((x) =>  (x.xaxis));
    const data = inflationkpis.slice(-12).map((x) =>  ({y: x.value, name: x.xaxis }));
    const latestValue = inflationkpis.at(-1)?.value;
    const showFirstandLast = true;
    const info = `${typeof latestValue === 'number' ? latestValue?.toFixed(2) : latestValue}%`;
    const tooltip =  {
      useHTML: true,
      headerFormat: '',
      pointFormatter: function () {
        return `<div>
        <div>United States | <b>${this.category}</b></div>
        <div>Inflation rate = ${ typeof this.y === 'number'?this.y?.toFixed(2) : this.y}%</div>
        </div>`;
      },
    };
    return { type, title, footerText, data, info, tooltip, categories, showFirstandLast };
  }

  generateUnemploymentkpiOpts(unemploymentkpis) {
    const that = this;
    const type = 'line';
    const showFirstandLast = true;
    const title = 'Unemployment rate';
    const footerText =  'Source: U.S. Bureau of Labor Statistics';
    const categories = unemploymentkpis.slice(-12).map((x) =>  (x.xaxis));
    const data = unemploymentkpis.slice(-12).map((x) =>  ({y: x.value, name: x.xaxis }));
    const latestValue = unemploymentkpis.at(-1)?.value;
    const info = `${typeof latestValue === 'number' ? latestValue?.toFixed(2) : latestValue}%`;
    const tooltip =  {
      useHTML: true,
      headerFormat: '',
      pointFormatter: function () {
        return `<div>
        <div>United States | <b>${this.category}</b></div>
        <div>Unemployment rate = ${ typeof this.y === 'number'?this.y?.toFixed(2) : this.y}%</div>
        </div>`;
      },
    };

    return { type, title, footerText, data, info, tooltip, categories, showFirstandLast };
  }

  generateUnemployementOpts(dataSource): any {
    const step = 12;
    const unemployementTypeKeyPair: any = {};

    const cbsa = this.unemploymentForm.get('cbsa').value?.map((opt) => opt?.id) || [];
    const state = this.unemploymentForm.get('state').value?.map((opt) => opt?.id) || [];

    const selectedIds = this.unemploymentFilter === 'cbsa' ? cbsa : state;

    const yAxisLabel = 'Unemployment rate';
    const xAxisLabel = 'Year';
    const xCategories = dataSource[0]?.month;
    const defaultLineConf = { type: 'line', marker: { enabled: false}};

    dataSource.reduce((acc, nxt): {UnemploymentType?: Array<Highcharts.SeriesLineOptions>} => {
      if (nxt.type === 'National') {
        acc['National'] = [{
          id: nxt.type,
          name: nxt.type,
          colorIndex: 0,
          data: nxt.unemployment,
          enableMouseTracking: true,
          tooltip: {
            useHTML: true,
            headerFormat: '',
            pointFormatter: function () {
              return `<div>
              <div>United States | <b>${this.category}</b></div>
              <div>Unemployment rate = ${this.options?.y}%</div>
              </div>`;
            },
          },
          ...defaultLineConf,
        }];
      }
      if (nxt.type?.toLowerCase() !== this.unemploymentFilter) return acc;
      if (selectedIds.includes(nxt.id)) {
        const key = nxt.type?.toLowerCase();
        if (acc[key]) {
          acc[key] = [...acc[key],  {
            data: nxt.unemployment,
            linkedTo: key,
            name: nxt.type,
            colorIndex: 1,
            tooltip: {
              useHTML: true,
              headerFormat: '',
              pointFormatter: function () {
                return `<div>
                <div>${nxt.name} | <b>${this.category}</b></div>
                <div>Unemployment rate = ${this.options?.y}%</div>
                </div>`;
              },
            },
            ...defaultLineConf,
          }];
        } else {
          acc[key] = [{
            data: nxt.unemployment,
            id: key,
            name:  nxt.type,
            tooltip: {
              useHTML: true,
              headerFormat: '',
              pointFormatter: function () {
                return `<div>
                <div>${nxt.name} | <b>${this.category}</b></div>
                <div>Unemployment rate = ${this.options?.y}%</div>
                </div>`;
              },
            },
            colorIndex: 1,
            ...defaultLineConf,
          }];
        }
      } return acc;
    }, unemployementTypeKeyPair);

    if (unemployementTypeKeyPair[this.unemploymentFilter]?.length > 100) {
      unemployementTypeKeyPair[this.unemploymentFilter].forEach((s) => {
        s.crisp = false;
        s.animation = false;
        s.stickyTracking = false;
        s.inactiveOtherPoints = true;
        s.enableMouseTracking = false;
      });
    }
    const series: Array<any> = Object.values(unemployementTypeKeyPair).flat().reverse();
    return {
      step,
      series,
      yAxisLabel,
      xAxisLabel,
      xCategories,
    };
  }

  handleUnemployementChange(e): void {
    if (!e.value) return;
    this.unemploymentFilter = e.value;
    this.unemploymentOptions = this.generateLineGraph(this.generateUnemployementOpts(this.unemploymentData));
  }

  generateWageOpts(dataSource: any[]): any {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const step = 4;
    const yAxisLabel = 'Annual wage';
    const xAxisLabel = 'Year';
    const xCategories = dataSource[0].quarters;
    const colors = ['#80BBAD', '#435354', '#5975BB', '#013880','#3F2299', '#DA6944', '#8A3800',
      '#A271D6', '#17E88F', '#3E8E9D', '#1C511D', '#998E00', '#D19E00', '#FFA600', '#79388C',
      '#B13685', '#F85459', '#FF8033', '#3A64A1', '#91A6D3', '#D0A3DF'];

    const selectedIndustryIds = this.wageForm.get('industry').value?.map((val) => val.id);
    const selectedLevelExp = this.wageForm.get('experience').value;
    const filteredDataSource = dataSource.filter((data) => (selectedIndustryIds.includes(data.industryID) && selectedLevelExp === data.yearsExperience));
    filteredDataSource.sort((prev, next) =>   selectedIndustryIds.indexOf(prev?.industryID) - selectedIndustryIds.indexOf(next.industryID));
    const series: Array<Highcharts.SeriesLineOptions> = filteredDataSource.map((data) => {
          return ({
            data: data.wages,
            type: 'line',
            name: data.industryName,
            marker: { enabled: false }, 
            tooltip: {
              useHTML: true,
              headerFormat: '',
              pointFormatter: function () {
                return `<div>
                <div>${this.series.name} | <b>${this.category}</b></div>
                <div>${that.yearofExp(data.yearsExperience)} wage = $${
                  that.commonService.numberWithCommas(this.options?.y)} per year</div>
                </div>`;
              },
            },
          });
    });

    return {
      step,
      colors,
      series,
      yAxisLabel,
      xAxisLabel,
      xCategories,
    };
  }
  
  getArticlesForLast90Days(email) {
    if (!this.hasArticleAccess) return 

    const publishedStartDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 3,
      new Date().getDate(),
    ).toLocaleDateString('en-US');
    const publishedEndDate = new Date().toLocaleDateString('en-US');
    const limit = 25;

    this.articleService.getArticleList({ publishedStartDate, publishedEndDate, limit }, email).subscribe((data) => {
      this.articles = data.sort((a,b) => Date.parse(b.datePublished) - Date.parse(a.datePublished));
    });
  }

  initThoughtLeadership() {
    this.articleService.getThoughtLeadership().subscribe((data) => {
      this.thoughtLeadership = data;
    });
  }

  stringToDate(datestring: string): Date {
    if (!datestring) return null;
    const unixTime = Date.parse(datestring);
    if (Number.isNaN(unixTime)) return null;

    return new Date(unixTime);
  }

  kpiBaseOptions = ({
    data,
    type,
    info,
    title,
    showFirstandLast,
    tooltip,
    footerText,
    categories,
  }: any) :Highcharts.Options => {
    let options:Highcharts.Options = {};
    const [first, last] = [data[0], data.at(-1)];
    const arrow = first.y > last.y ? 'arrow_downward' : (last.y > first.y ? 'arrow_upward' : 'arrow_flat');
    const columnSeriesConf: Highcharts.SeriesColumnOptions = {
      type: 'column',
      pointWidth: 19,
      borderRadius: 2,
      color: '#E6EAEA',
      states: {
        hover: { color: '#5975BB'}, select: { color: '#5975BB'}
      },
      data,
    };
    const lineSeriesConf: Highcharts.SeriesLineOptions = {
      type: 'line',
      lineWidth: 2,
      marker: {
        enabled: false,
      },
      data,
    };
    
    if (type === 'line') {
      options = {
        tooltip,
        ...options,
        series: [lineSeriesConf],
      };
    }
    if (type === 'column') {
      options = {
        ...options,
        tooltip,
        xAxis: {
          labels: {
            // disable xaxis from rotating
            autoRotation:[0],
            formatter() {
              if(this.isFirst || this.isLast) {
                return `${this.value}`;
            } else {
                return '';
            }
            },
          },
          type: 'category',
        },
        series: [columnSeriesConf],
      };
    }

    return {
      chart: {
        height: '240px',
        events: {
          render() {
            this.renderer.text(`<span class="highcharts-custom-icon ${arrow}"></span><span class="ml-2">${info}</span>`, 0, 90, true)
            .css({
              color: '#012A2D',
              fontSize: '60px',
              fontWeight: '500',
              lineHeight: '60px',
            }).addClass('highchart-custom-label').add();
            
            this.renderer.label(footerText, 0, 220)
            .css({
              fontSize: '8px',
              lineHeight: '12px',
              color: '#959595'
            }).add();

            if (type === 'column') this.series[0].data.at(-1).select();
          }
        },
      },
      xAxis: {
        lineWidth: 2,
        lineColor: '#E6EAEA',
        categories: categories ? categories : null,
        labels: { 
          rotation: 0,
          align: 'center',
          style: {
            textOverflow: 'none',
          },
         formatter: showFirstandLast ? function() {
          if(this.isFirst || this.isLast) {
            return `${this.value}`;
        } else {
            return '';
        }
        }: null },
        ...options?.xAxis,
      },
      yAxis: {
        visible: false,
      },
      title: { text: title },
      ...options,
    };
  };

  generateColumnGraph = ({
    data,
    title,
    step,
    categories,
    footer,
    subtitle,
    formatter,
    yAxisLabel,
    xAxisLabel,
  }): Highcharts.Options => {
    const footerStyle = this.axisLabelStyle;
    return {
      chart: {
        type: 'column',
        events: {
          render() {
            this.renderer.label(footer, 0, 380).css(footerStyle).add();
          }
        }
      },
      tooltip: { formatter },
      xAxis: {
        lineWidth: 1,
        lineColor: '#565656',
        gridLineWidth: 0,
        categories,
        title: {
          text: xAxisLabel,
          style: this.axisTitleStyle,
        },
        labels: {
          step,
          style: this.axisLabelStyle,
        }
      },
        yAxis: {
        lineWidth: 1,
        lineColor: '#565656',
        title: {
          text: yAxisLabel,
          style: this.axisTitleStyle,
        },
      },
      title: {
        useHTML: true,
        text: `<div class="eds-h6">${title}</div><div class="eds-body2">${subtitle || ''}</div>`,
      },
      series: data.map((d) => ({
        data: d,
        type: 'column',
        pointWidth: 25,
        color: '#80BBAD',
        states: {
          hover: { color : '#5975BB'},
        },
      })),
    };
  };

  generateLineGraph = ({
    step,
    title,
    colors,
    series,
    footer,
    subtitle,
    formatter,
    xAxisLabel,
    yAxisLabel,
    xCategories,
  }): Highcharts.Options => {
    return {
      colors: colors || ['#012A2D', '#80BBAD'],
      plotOptions: { line: { 
        marker: { enabled: false },
        lineWidth: 2,
        animation: false,
        turboThreshold: 1,
      },
     },
      chart: {
          type: 'line',
          zoomType: 'x',
          animation: false,
        events: {
          render() {
            this.renderer.label(footer, 0, 380)
            .css({
              fontSize: '12px',
              lineHeight: '16px',
              color: '#959595'
            }).add();
          },
        }
      },
      legend: { 
        enabled: true,
        align: 'left',
        squareSymbol: false,
        itemMarginBottom: 10,

      },
      tooltip: {
        useHTML: true,
        animation: false,
        style: {},
        formatter,
      },
      xAxis: {
        categories: xCategories,
        lineWidth: 1,
        lineColor: '#565656',
        gridLineWidth: 0,
        title: {
          margin: 24,
          text: xAxisLabel,
          style: this.axisTitleStyle,
        },
        labels: { 
          formatter: function() {
            if (typeof this.value === 'string') {
              return this.value?.slice(0,4) || this.value; 
            }
            },
          style: this.axisLabelStyle,
          step: step,
        }
      },
      yAxis: {
        lineWidth: 1,
        lineColor: '#565656',
        title: {
          text: yAxisLabel,
          style: this.axisTitleStyle,
        },
        labels: {
            enabled: true, 
            style: this.axisLabelStyle,
            formatter: yAxisLabel === 'Unemployment rate' ? function() {
              return this.value + '%';
            }: null
          },
      },
      title: {
        useHTML: true,
        text: `<div class="eds-h6">${title || ''}</div><div class="eds-body2">${subtitle || ''}</div>`,
      },
      series,
    };
  };

  generatePieGraph = ({
    title,
    data,
    footer,
    subtitle,
  }): Highcharts.Options => {
    const axisLabel = this.axisLabelStyle;
    return {
      chart: {
        type: 'pie',
        height: 500,
        events: {
          render() {
            this.renderer.label(footer, 0, 480)
            .css(axisLabel).add();}}
      },
      legend: {
        width: 200,
        margin: 50,
        align: 'left',
        useHTML: true,
        enabled: true,
        symbolWidth: 16,
        symbolHeight: 16,
        itemMarginBottom: 8,
      },
      title: {
        useHTML: true,
        text: `<div class="eds-h6">${title}</div> <div class="eds-body2">${subtitle || ''}</div>`,
      },
      series: [{
        type: 'pie',
        name: 'Share',
        dataLabels: {enabled: false},
        size: 262,
        innerSize: '70%',
        showInLegend: true,
        data,
    }]
    };
  };

  openNewsletterDialog() {
   this.dialogService.open(NewsLetterDialogComponent, {
      data: { 
        staticData: this.staticData,
         email: this.email,
         userProfile: this.userProfile
         },
    }).afterClosed().subscribe((result) => {
      if (result) {
        const payload: NewsLetterConfig = {
          emailID: this.email,
          industries: result.industries?.map((industry) => industry.id),
          topics: result.topics?.map((topic) => topic.id),
        };

        this.updateSubscription(payload);
      }
    });
  }

  private updateSubscription(payload: NewsLetterConfig) {
    this.articleService.subscribeNewsletter(payload).subscribe(          
      () => {
        this.snackBarService.showSuccess("You've successfully subscribed to the weekly newsletter.");
        this.loadUserProfile();
      },
      (error) => {
        // condition for checking on parse error
        if (error.status === 201) {
          this.snackBarService.showSuccess("You've successfully subscribed to the weekly newsletter.");
        }
      },)
  }

  unsubscribe(): void {
    this.userProfileService.unsubscribeFromNewstletter(this.email).subscribe(() => {
      this.loadUserProfile();
    })
  }

  handleReroute(url: string): void {
    if (url.startsWith('/')) {
      this.router.navigate([`${url}`]);
    } else {
      window.location.href = url;
    }
  }

  createWageForm(): FormGroup {
    return new FormGroup({
      experience: new FormControl(10),
      industry: new FormControl([]),
    });
  }

  createUnemploymentForm(): FormGroup {
    return new FormGroup({
      cbsa: new FormControl([]),
      state: new FormControl([]),
    });
  }

  openInterestModal(): void {
    const conf: MatDialogConfig = {
      width: '540px',
      data: {
        closeDialog: () => this.MatDialog.close(),

      }
    };
    this.MatDialog = this.driveTimeDialogService.showEmeraldConfirmationDialog(this.interestModal, conf);
  }
}

type UnemploymentType = 'cbsa' | 'state' | 'National';