<table style="width: 100%; overflow-x: auto">
  <ng-container *ngFor="let column of columns">
    <tr
      *ngIf="column.label"
      [class]="'column-level-' + column.columnLevel"
      [style.display]="isOpened(column)"
    >
      <th [class]="'horizontal-table-empty-space'" >
        <ng-container
          *ngIf="column.cellTemplate"
          [ngTemplateOutlet]="column.cellTemplate"
          [ngTemplateOutletContext]="{ column: column }"
        ></ng-container>
      </th>
      <th
        [class]="
          'horizontal-table-header ' + 'column-level-' + column.columnLevel
        "
      >
        <ng-container>{{ column.label }}</ng-container>
      </th>
      <ng-container *ngIf="!isLoading; else loaderCell">
        <td
        [class]="'horizontal-table-row ' + 'column-level-' + column.columnLevel"
        *ngFor="let row of data"
        [style.width]="rowWidth"

      >
      <div class="horizontal-table-row-item">
        <ng-container
        *ngIf="column.dataTemplate"
        [ngTemplateOutlet]="column.dataTemplate"
        [ngTemplateOutletContext]="{ column: column, row: row }"
      ></ng-container>
      </div>
        <ng-container *ngIf="!column.dataTemplate">
          <ng-container [ngSwitch]="row[column.key] | typeof">
            <ng-container *ngSwitchCase="'number'">{{
              row[column.key] | number
            }}</ng-container>
            <ng-container *ngSwitchDefault>{{ row[column.key] }}</ng-container>
          </ng-container>
        </ng-container>
      </td>
      </ng-container>

    </tr>
  </ng-container>
</table>


<ng-template #loaderCell>
  <td class="loading" style="width: 100%" *ngFor="let row of ['']">
    <ngx-skeleton-loader
    [theme]="{ 'min-width': '120px', width: '120px', height: '22px' }"
  ></ngx-skeleton-loader>
  </td>
</ng-template>