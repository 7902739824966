import { Component, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfoDetailed } from './models/user-info';
import { Subject } from 'rxjs';
import { AnalyticsService } from '@services/analytics.service';
import { CommonService } from 'core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private commonService: CommonService
  ) {}

  @HostListener('document:click', ['$event'])
  trackUserClicks($event) {
    if (
      $event.target.nodeName === 'BUTTON' ||
      ($event.target.nodeName === 'SPAN' && $event.target.className?.includes('mat-button-wrapper'))
    ) {

      
      this.analyticsService.on('track', 'Button clicked', {
        component_name: $event.target.innerText,
        button_name: $event.target.innerText,
        description: `user clicked on the ${$event.target.innerText} button`,
      });
    }

    if ($event.target.nodeName === 'A') {
      const isOutBoundLink = $event.target?.href?.includes('https') || $event.target?.href?.includes('http');
      const title = isOutBoundLink ? 'Outbound Link Clicked' : 'Navigation Clicked';

      this.analyticsService.on('track', title, {
        url: window.location.href,
        destination: $event.target.href,
        search: window.location.search,
        cta_link_title: $event.target.innerText,
      });
    }
  }

  ngOnInit(): void {
    this.commonService.setMoment(moment);
  }

}
