export const environment = {
  baseUrl: '/',
  articleApiUrl: 'https://api-comp-test.azurewebsites.net',
  imageUrl: 'https://cbre-images-api-test.azurewebsites.net',
  indexingApiUrl: 'https://api-index-test.azurewebsites.net',
  ondemandApiUrl: 'https://api-ondemand-test.azurewebsites.net',
  educationApiUrl: 'https://educationapi-test.azurewebsites.net',
  eriApiUrl: 'https://api-eri-test.azurewebsites.net',
  blsApiUrl: 'https://api-bls-test.azurewebsites.net',
  adminUrl: 'https://api-admin-test.azurewebsites.net',
  laborplanApiUrl: 'https://cbre-laborplan-api-test.azurewebsites.net',
  esriSuggestApiUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest',
  competitionApiUrl: 'https://api-comp-test.azurewebsites.net',
  production: false,
  isResearch: true,
  uat: true,
  isInterviews: false,
  INTERVIEW_API_URL: 'https://api-comp-test.azurewebsites.net',
  OKTA_ISSUER_URL: 'https://login-uat.cbre.com/oauth2/default',
  OKTA_CLIENT_ID: '0oa1w0yb2polBPRjT0h8',
  THOUGHTSPOT_USERNAME: 'US_SVC_LA_TS_SF_INT',
  ANALYTICS_KEY: 'e3F324y30cELUJsJcrIdz2sWP2o1LUfD',
  GLV_HOME: 'http://glv-test.cbre.com/Home',
  puppeteerApiUrl: 'https://geonavigator-test-api.cbre.com/api/html-screenshot?code=_9ZLTdtv3u4B3wBWHvgm-oq-mMdKr_Bg1Fz5bTu8bP8gAzFu00HYUA==',
  geoskillFaqUrl: 'https://cbre.sharepoint.com/sites/intra-AdvisoryTransactionServices/SiteAssets/Forms/AllItems.aspx?id=%2Fsites%2Fintra%2DAdvisoryTransactionServices%2FSiteAssets%2FSitePages%2FAMS%2DConsulting%2FGeoSkill%5FFAQ%2Epdf&parent=%2Fsites%2Fintra%2DAdvisoryTransactionServices%2FSiteAssets%2FSitePages%2FAMS%2DConsulting',
  cartoIframeUrl: 'https://cbre-lbr-nlytcs-stg.carto.solutions/',
  ANALYTICS_UUID_API: 'https://mktgservice.cbre.com/v2/uuid-generator'
};
