import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { filter, BehaviorSubject, combineLatest, take } from 'rxjs';

import { BaseService } from './base.service';
import { UserProfileService } from './userProfile.service';
import { FormService } from 'core';
import { NavigationEnd, Router } from '@angular/router';
import { appRoutesMapping } from '../app-routing.module';
import { SKIP_DEFAULT_ERROR_HANDLER } from '../app.module';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends BaseService {
  _analyticsInstance = null;
  currentRoute$$ = new BehaviorSubject<{ name: string; url: string } | null>(null);
  uuid$$ = new BehaviorSubject(null);
  uuid$ = this.uuid$$.asObservable();
  uuid;

  constructor(
    override http: HttpClient,
    private userProfileService: UserProfileService,
    private formService: FormService,
    private router: Router
  ) {
    super(http);
  }

  init() {
    this._analyticsInstance = AnalyticsBrowser.load({ writeKey: environment.ANALYTICS_KEY });
    this.formService.setAnalytics(this.on.bind(this));
    this.identifyUser();
    
    const routeEntries = Object.entries(appRoutesMapping);
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        const currentRoute = routeEntries.find(([_, route]) => `/${route}` === value.url);

        if (currentRoute) {
          const trackRoute = {
            name: currentRoute.at(0),
            url: currentRoute.at(1),
          };
          this.currentRoute$$.next(trackRoute);
        } else {
          const currentRoute = routeEntries.find(([_, route]) => value?.url?.includes(route));
          const trackRoute = {
            name: currentRoute.at(0),
            url: currentRoute.at(1),
          };
          this.currentRoute$$.next(trackRoute);
        }

        this.on('page', 'Page viewed', {
          path: window.location.pathname,
          referrer: document.referrer,
          url: window.location.search,
          title: document.title,
          browser: navigator.userAgent,
        });
      }
    });
  }

  identifyUser() {
    this.userProfileService.userData$.pipe(
      filter(x => !!x?.email),
      take(1)
    ).subscribe((userData) => {
      this.http.post(environment.ANALYTICS_UUID_API, {}, {
        params: {email: userData.email},
        headers: {
          [SKIP_DEFAULT_ERROR_HANDLER]: 'true'
        }
      }).subscribe((uuid) => {
        this.uuid = uuid;
        this._analyticsInstance.identify(uuid, {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        });
      })
    })
  }

  on(action: 'track' | 'page', eventName, extraArgs) {
    const self = this;

    if (self._analyticsInstance === null) return;

    const args = {
      product: 'GeoNavigator',
      module: self.currentRoute$$?.getValue()?.name,
      screen_name: window.location.href,
      ...extraArgs,
    };

    if (this.uuid) {
      args.uuid = this.uuid;
    }

    self._analyticsInstance[action](eventName, args);
  }

  public get analytics() {
    return this._analyticsInstance;
  }
}
